.App {
  text-align: left;
  background-color: #ABCBC8;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

a{
  text-decoration: none;
  color: inherit;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: NeueHaasDisplayBold;
  src: url(fonts/NeueHaasDisplay-Bold.woff);
}

@font-face {
  font-family: NeueHaasDisplayMedium;
  src: url(fonts/NeueHaasDisplay-Mediu.woff);
}

@font-face {
  font-family: NeueHaasDisplayLight;
  src: url(fonts/NeueHaasDisplay-Light.woff);
}

@font-face {
  font-family: NeueHaasDisplayRoman;
  src: url(fonts/NeueHaasDisplay-Roman.woff);
}

@font-face {
  font-family: NeueHaasDisplayThin;
  src: url(fonts/NeueHaasDisplay-Thin.woff);
}

@font-face {
  font-family: TradeGothic;
  src: url(fonts/TradeGothicLT.ttf);
}
@font-face {
  font-family: TradeGothicBold;
  src: url(fonts/TradeGothicLT-Bold.ttf);
}

.App-navigation {
  margin-left:60px;
  margin-right: 60px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  font-family: TradeGothic;
  font-size: 16px;
  color: black;
}

.App-header {
  margin-left:60px;
  margin-right: 60px;
  padding-top: 60px;
  font-family: TradeGothicBold;
  font-size: calc(100px + 2vmin);
  color: black;
}

.App-subHeader{
  margin-left:60px;
  margin-right: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
  font-family: TradeGothic;
  font-size: calc(36px + 2vmin);
  color: black;
}

.line{
  margin-left: 60px;
  margin-right: 60px;
  height: 2px;
  background: #181818;
  opacity: 25%;
}

.App-currently{
  margin-left:60px;
  margin-right: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
  font-family: TradeGothic;
  font-size: calc(16px + 2vmin);
  color: black;
}

.subhead{
  font-size: calc(12px + 1.5vmin);
  letter-spacing: 1.5px;
}

.row{
margin-top:40px;
margin-left:60px;
font-family: NeueHaasDisplayRoman;
}

.bufferColumn{
  padding-top:16px;
}

.bufferColumn2{
  padding-top:40px;
}

.App-aboutThreeColumn{
  margin-left:60px;
  margin-right: 60px;
}

.App-experiencesThreeColumn{
  padding-top:40px;
  margin-left:60px;
  margin-right: 60px;
}

.App-experiencesThreeColumn2{
  padding-top:60px;
  margin-left:60px;
  margin-right: 60px;
}

.col1{
  flex: 1;
  order:1;
  font-family: NeueHaasDisplayRoman;
  letter-spacing: .6px;
  line-height: 1.33;
}

.col2{
  flex: 1;
  order:2;
  font-family: NeueHaasDisplayRoman;
  letter-spacing: .6px;
  line-height: 1.33;
}

.col3{
  flex: 1;
  order:3;
  font-family: NeueHaasDisplayRoman;
  letter-spacing: .6px;
  line-height: 1.33;

}

.colImage1{
  flex: 1;
  order:1;
  margin-right:38px;
}

.colImage2{
  flex: 1;
  order:2;
}

.colImage3{
  flex: 1;
  order:3;
}

.title{
  display: inline-block;
  margin-left:60px;
  margin-right:60px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-family: NeueHaasDisplayRoman;
  letter-spacing: .5px;
  line-height: 1.33;
  font-size: calc(8px + 2vmin);
  color: black;
}

img{
  width: 100%;
height: auto;
vertical-align: bottom;
/* -webkit-filter: drop-shadow(5px 5px 5px #222);
filter: drop-shadow(5px 5px 5px #222); */
}

.colCopy{
  width:100%;
  max-width:50%;
}

.space {
  display: block;
  padding-left:20px;
  padding-right:20px;
  /* margin: 0 0 0 20px; */
}

.br {
  display: block;
  margin-bottom: 10px;
}

.brlarge {
  display: block;
  margin-bottom: 1em;
}

.brMedium {
  display:block;
  margin-bottom: 1.72em;
}

.brbreak {
  display: block;
  margin-top: 60px;
  margin-bottom: 0px;
}

.max{
  width:100%;
  max-width:80%;
}

.hvr-bounce-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-out:hover, .hvr-bounce-out:focus, .hvr-bounce-out:active {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Buzz */
@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
.hvr-buzz {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz:hover, .hvr-buzz:focus, .hvr-buzz:active {
  -webkit-animation-name: hvr-buzz;
  animation-name: hvr-buzz;
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* Buzz Out */
@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
.hvr-buzz-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-buzz-out:hover, .hvr-buzz-out:focus, .hvr-buzz-out:active {
  -webkit-animation-name: hvr-buzz-out;
  animation-name: hvr-buzz-out;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}


.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.hvr-bounce-in:hover, .hvr-bounce-in:focus, .hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

.footer{
  padding-top:200px;
}

@media only screen and (min-width: 850px) {

  .App-aboutThreeColumn{
    margin-left:60px;
    margin-right: 60px;
    display: flex;
  }

  .App-experiencesThreeColumn{
    padding-top:0px;
    margin-left:60px;
    margin-right: 60px;
    display: flex;
  }

}

@media (max-width: 920px) {
  .App-header {
    font-size: calc(72px + 2vmin);
  }

  .row{
    margin-top:0px;
    }

  .App-experiencesThreeColumn{
    flex-flow: row nowrap;
    padding-top:0px;
  }

  .App-aboutThreeColumn{
    flex-flow: row nowrap;
  }

  .App-subHeader{
    font-size: calc(28px + 2vmin);
  }

  .brlarge {
    display:block;
    margin-bottom: 3em;
  }

  .max{
    width:100%;
    max-width:70%;
  }

  .special {
    display:block;
    margin-bottom: 0em;
  }
}

@media only screen and (max-width: 850px) {

  .App-currently{
    padding-bottom: 10px;
  }

  .special {
    display:block;
  }

  .row{
    padding-top:40px;
  }

  .bufferColumn{
    padding-top:2px;
  }

  .bufferColumn2{
    padding-top:30px;
  }

}

@media (max-width: 700px) {
  .App-header {
    font-size: calc(48px + 2vmin);
  }

  .max{
    width:100%;
    max-width:80%;
  }

  .special {
    display:block;
  }

}

@media (max-width: 480px) {
  .App-navigation{
    margin-left:40px;
    margin-right: 40px;
  }

  .bufferColumn2{
    padding-top:30px;
  }

  .citySpan{
    display:none;
  }

  .line{
    margin-left:40px;
    margin-right: 40px;
  }

  .App-currently{
    margin-left:40px;
    margin-right: 40px;
  }

  .App-header {
    font-size: calc(28px + 2vmin);
    margin-left:40px;
    margin-right: 40px;
  }

  .App-subHeader{
    font-size: calc(16px + 2vmin);
    margin-left:40px;
    margin-right: 40px;
  }

  .App-experiencesThreeColumn{
    flex-flow: row nowrap;
    margin-left:40px;
    margin-right: 40px;
  }

  .App-aboutThreeColumn{
    flex-flow: row nowrap;
    margin-left:40px;
    margin-right: 40px;
  }

  .row{
    margin-left:40px;
    margin-right:40px;
    }

    .max{
      width:100%;
      max-width:80%;
    }

}
